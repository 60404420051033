import React from 'react';

import { BLOCKS, MARKS, Heading1, Heading2, Heading3 } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import tw from "twin.macro";
import styled from "styled-components";

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>
const H1 = ({ children }) => <h1>{children}</h1>
const H2 = ({ children }) => <h2>{children}</h2>
const H3 = ({ children }) => <h3>{children}</h3>
const ol = ({ children }) => <ol>{children}</ol>
const li = ({ children }) => <li>{children}</li>




interface Props {
    json: Document
}
  

const TextRich = ({ json }: Props) => {

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
    }
  }

  return (
    <TextRichWrapper>
    {documentToReactComponents(json, options)}
    </TextRichWrapper>
  );
};

export default TextRich;

const TextRichWrapper = styled.div`
a{
  text-decoration: underline;
  color: ${props => props.theme.colors.headingColor};

  &:hover{
    text-decoration: underline;
    color: ${props => props.theme.colors.primaryDark};
  }
}

.bold{
  font-weight: 700;
}
`;
